<template>
  <ion-page>
    <cyan-page-wrapper
      title="Exit Poll"
      nav1="EXIT POLL"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      backRoute="/4F"
      class="directorio"
    >
        <div class="mobileShow">
          <div class="banner nivel1">{{ datosCentro.nombre }}</div>
          <div class="banner nivel2">{{ ambito }}</div>
        </div>

        <div v-for="datosConvocatoria in convocatorias" :key="datosConvocatoria.id">
          <div class="banner nivel1 hdr">{{ datosConvocatoria.nombre }}</div>

          <ion-grid>
            <ion-row>
              <ion-col
                size-lg="2"
                size-md="2"
                size-s="3"
                size="4"
                v-for="partido in datosConvocatoria.orden_partidos"
                :key="partido"
              >
                <div class="partido-outer">
                  <div
                    @click="addVoto(partido, datosConvocatoria.id)"
                    :class="[
                      'partido-top',
                      datosConvocatoria.mapa_partidos[partido].es_oscuro ? 'partido-oscuro' : 'partido-claro',
                      (votoActivo[datosConvocatoria.id] && votoActivo[datosConvocatoria.id][partido]) ? 'partido-activo' : '',
                    ]"
                    :style="{ backgroundColor: '#' + datosConvocatoria.mapa_partidos[partido].color }"
                  >
                    <div class="partido-siglas">{{ datosConvocatoria.mapa_partidos[partido].siglas }}</div>
                    <div class="partido-nombre" lang="en">{{ datosConvocatoria.mapa_partidos[partido].nombre }}</div>
                    <div class="partido-votos">{{ estado[datosConvocatoria.id][partido] }}</div>
                    
                  </div>

                  <div
                    class="partido-bottom"
                  >
                    <ion-button
                      expand="block"
                      size="small"
                      @click="delVoto(partido, datosConvocatoria.id)"
                      color="secondary"
                      >Corregir</ion-button
                    >
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
    </cyan-page-wrapper>
  </ion-page>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";

import { defineComponent } from "vue";
import ud from '@/modules/cyanUserData';
import CyanPageWrapper from '@/components/CyanPageWrapper.vue'
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import { territorios } from "@/modules/cyanRegions";

export default defineComponent({
  name: "AgenteConteo",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    CyanPageWrapper
  },

  setup() {
    return {
    }
  },
  data() {
    return {
      votoActivo: {},
    };
  },
  computed: {
    convocatorias() {
      return ud('convocatoriasBU', {})
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    estado() {
      return ud('bocaUrna');
    }

  },
  methods: {
    async addVoto(partido: any, convocatoria: any) {
     await this.alterVoto(partido, convocatoria, true);
    },

    async delVoto(partido: any, convocatoria: any) {
      await this.alterVoto(partido, convocatoria, false);
    },
    async alterVoto(partido: any, convocatoria: any, add: boolean) {

      const va = (this.votoActivo as any);

      if (typeof va[convocatoria] === "undefined")
        va[convocatoria] = {};

      if (typeof va[convocatoria][partido] === "undefined")
        va[convocatoria][partido] = 0;

      // Evitar doble clic (a mejorar)

      if (va[convocatoria][partido]) return;

      va[convocatoria][partido] += 2;
      const diminishVaClosure = (function(c,p,va) {
        return function() {
          va[c][p]--;
        }
      })(convocatoria, partido, va);

      setTimeout(diminishVaClosure, ud('exitPollDebounce', 700));

      // precargar el cambio

      const conteoDummy =  {
        /* eslint-disable @typescript-eslint/camelcase */
        centro_id: this.datosCentro.id,
        convocatoria_id: convocatoria,
        partido_id: partido,
        ok: true,
        /* eslint-enable @typescript-eslint/camelcase */
        votos: this.estado[convocatoria][partido] + (add ? 1 : -1)
      };

      store.commit("storeBocaUrna",conteoDummy);

      const r = await cyanRequest(
        "busl/" +
          (add ? "addBocaUrna" : "subBocaUrna") +
          "/" +
          convocatoria +
          "/" +
          partido,
        {
          needsAuth: true,
          isPost: true,
          silent: false,
          isModal: false,
        }
      );

      va[convocatoria][partido]--;

      if (r.ok) {
        store.commit( "storeBocaUrna", r);
      }
      else this.actualizarDatos();
    },

    actualizarDatos() {
      cyanRequest(
        "busl/bocaUrna/",
        {
          needsAuth: true,
          silent: true,
          isModal: false,
        }
      ).then(function (r) {
        if (r.ok) {
          store.commit("patchUserData", r);
        }
      });
    },
  },

  watch: {
  },
});
</script>

<style scoped>
.partido-top {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  flex: 1 1 auto;
  text-align: center;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
}

.partido-votos {
  font-size: 200%;
  padding: 10px;
}

.partido-oscuro {
  color: white;
}

.candidato-voto {
  color: white;
  background: #050;
}

.candidato-no-voto {
  background: white;
}

.partido-activo {
  opacity: 0.5;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.partido-outer {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  height: 100%;
  display: flex;
  flex-direction:column;
}

.partido-nombre {
  display: none;
  font-weight: bold;
  font-size: 75%;
  text-transform: uppercase;
  hyphens: auto;
  min-height: 3em;
}

</style>